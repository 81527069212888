import React from "react";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
// import { TermsAndConditonsQuery} from "../../prismic/staticQueries";
import Layout from "../../layout";
import { Subscription,BreadCrumbBanner } from "../../components/common";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CountUp from "react-countup";
import banner_image from "../../images/siteImages/privacypolicy/Privacy-Policy-Security.jpg";



const TermsAndConditions = () => {
  const language = useSelector((state) => state.language);
  console.log("language",language);
  
 //  const TermsAndConditionsData = TermsAndConditonsQuery(language);
   console.log("test"); 
  // // const { seo_page_title, seo_page_description, seo_page_keywords } =
  // //   PrivacyPolicyData;
  // const {
  //   termsandconditionsheading,
  //   termsandconditionsbody,
  // } = TermsAndConditionsData;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
   
    <Layout >
    <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg p-top-100 p-bottom-110 faqs">
        <Container className="">
          <Row>
            <Col lg={12}>
            <h2 className="pt-5 mt-5 text-bold-md text-black">
            Terms and Conditions
                {/* {termsandconditionsheading.text} */}
              </h2>
            <p className="text-gray mt-5">
            {/* {termsandconditionsbody.text} */}
            </p>
            </Col>
          </Row>
        </Container>
      </section>

      <Subscription />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(TermsAndConditions);
